import React from 'react'
import MediaQuery from "react-responsive";
import exhibition01 from '../assets/images/exhibition/nobody/01.jpg'
import exhibition02 from '../assets/images/exhibition/nobody/02.jpg'
import exhibition03 from '../assets/images/exhibition/nobody/03.jpg'
import exhibition04 from '../assets/images/exhibition/nobody/04.jpg'
import exhibition05 from '../assets/images/exhibition/nobody/05.jpg'
import exhibition06 from '../assets/images/exhibition/nobody/06.jpg'
import exhibition07 from '../assets/images/exhibition/nobody/07.jpg'
import exhibition08 from '../assets/images/exhibition/nobody/08.jpg'
import exhibition09 from '../assets/images/exhibition/nobody/09.jpg'
import exhibition10 from '../assets/images/exhibition/nobody/10.jpg'
import exhibition11 from '../assets/images/exhibition/nobody/11.jpg'
import exhibition12 from '../assets/images/exhibition/nobody/12.jpg'
import exhibition13 from '../assets/images/exhibition/nobody/13.jpg'
import exhibition14 from '../assets/images/exhibition/nobody/14.jpg'
import exhibition15 from '../assets/images/exhibition/nobody/15.jpg'
import exhibition16 from '../assets/images/exhibition/nobody/16.jpg'
import exhibition17 from '../assets/images/exhibition/nobody/17.jpg'
import exhibition18 from '../assets/images/exhibition/nobody/18.jpg'
import exhibition19 from '../assets/images/exhibition/nobody/19.jpg'
import exhibition20 from '../assets/images/exhibition/nobody/20.jpg'
import exhibition21 from '../assets/images/exhibition/nobody/21.jpg'
import exhibition22 from '../assets/images/exhibition/nobody/22.jpg'
import exhibition23 from '../assets/images/exhibition/nobody/23.jpg'
import exhibition24 from '../assets/images/exhibition/nobody/24.jpg'
import exhibition25 from '../assets/images/exhibition/nobody/25.jpg'
import exhibition26 from '../assets/images/exhibition/nobody/26.jpg'
import exhibition27 from '../assets/images/exhibition/nobody/27.jpg'
import exhibition28 from '../assets/images/exhibition/nobody/28.jpg'
import exhibition29 from '../assets/images/exhibition/nobody/29.jpg'
import exhibition30 from '../assets/images/exhibition/nobody/30.jpg'
import thumb01 from '../assets/images/exhibition/nobody/01.jpg'
import thumb02 from '../assets/images/exhibition/nobody/02.jpg'
import thumb03 from '../assets/images/exhibition/nobody/03.jpg'
import thumb04 from '../assets/images/exhibition/nobody/04.jpg'
import thumb05 from '../assets/images/exhibition/nobody/05.jpg'
import thumb06 from '../assets/images/exhibition/nobody/06.jpg'
import thumb07 from '../assets/images/exhibition/nobody/07.jpg'
import thumb08 from '../assets/images/exhibition/nobody/08.jpg'
import thumb09 from '../assets/images/exhibition/nobody/09.jpg'
import thumb10 from '../assets/images/exhibition/nobody/10.jpg'
import thumb11 from '../assets/images/exhibition/nobody/11.jpg'
import thumb12 from '../assets/images/exhibition/nobody/12.jpg'
import thumb13 from '../assets/images/exhibition/nobody/13.jpg'
import thumb14 from '../assets/images/exhibition/nobody/14.jpg'
import thumb15 from '../assets/images/exhibition/nobody/15.jpg'
import thumb16 from '../assets/images/exhibition/nobody/16.jpg'
import thumb17 from '../assets/images/exhibition/nobody/17.jpg'
import thumb18 from '../assets/images/exhibition/nobody/18.jpg'
import thumb19 from '../assets/images/exhibition/nobody/19.jpg'
import thumb20 from '../assets/images/exhibition/nobody/20.jpg'
import thumb21 from '../assets/images/exhibition/nobody/21.jpg'
import thumb22 from '../assets/images/exhibition/nobody/22.jpg'
import thumb23 from '../assets/images/exhibition/nobody/23.jpg'
import thumb24 from '../assets/images/exhibition/nobody/24.jpg'
import thumb25 from '../assets/images/exhibition/nobody/25.jpg'
import thumb26 from '../assets/images/exhibition/nobody/26.jpg'
import thumb27 from '../assets/images/exhibition/nobody/27.jpg'
import thumb28 from '../assets/images/exhibition/nobody/28.jpg'
import thumb29 from '../assets/images/exhibition/nobody/29.jpg'
import thumb30 from '../assets/images/exhibition/nobody/30.jpg'
import pcthumb01 from '../assets/images/exhibition/nobody/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/exhibition/nobody/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/exhibition/nobody/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/exhibition/nobody/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/exhibition/nobody/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/exhibition/nobody/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/exhibition/nobody/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/exhibition/nobody/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/exhibition/nobody/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/exhibition/nobody/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/exhibition/nobody/thumbnail/11.jpg'
import pcthumb12 from '../assets/images/exhibition/nobody/thumbnail/12.jpg'
import pcthumb13 from '../assets/images/exhibition/nobody/thumbnail/13.jpg'
import pcthumb14 from '../assets/images/exhibition/nobody/thumbnail/14.jpg'
import pcthumb15 from '../assets/images/exhibition/nobody/thumbnail/15.jpg'
import pcthumb16 from '../assets/images/exhibition/nobody/thumbnail/16.jpg'
import pcthumb17 from '../assets/images/exhibition/nobody/thumbnail/17.jpg'
import pcthumb18 from '../assets/images/exhibition/nobody/thumbnail/18.jpg'
import pcthumb19 from '../assets/images/exhibition/nobody/thumbnail/19.jpg'
import pcthumb20 from '../assets/images/exhibition/nobody/thumbnail/20.jpg'
import pcthumb21 from '../assets/images/exhibition/nobody/thumbnail/21.jpg'
import pcthumb22 from '../assets/images/exhibition/nobody/thumbnail/22.jpg'
import pcthumb23 from '../assets/images/exhibition/nobody/thumbnail/23.jpg'
import pcthumb24 from '../assets/images/exhibition/nobody/thumbnail/24.jpg'
import pcthumb25 from '../assets/images/exhibition/nobody/thumbnail/25.jpg'
import pcthumb26 from '../assets/images/exhibition/nobody/thumbnail/26.jpg'
import pcthumb27 from '../assets/images/exhibition/nobody/thumbnail/27.jpg'
import pcthumb28 from '../assets/images/exhibition/nobody/thumbnail/28.jpg'
import pcthumb29 from '../assets/images/exhibition/nobody/thumbnail/29.jpg'
import pcthumb30 from '../assets/images/exhibition/nobody/thumbnail/30.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Exhibitionnav from '../components/Exhibitionnav'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: exhibition01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: exhibition02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: exhibition03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: exhibition04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: exhibition05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: exhibition06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: exhibition07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: exhibition08,
    thumbnail: pcthumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: exhibition09,
    thumbnail: pcthumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: exhibition10,
    thumbnail: pcthumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: exhibition11,
    thumbnail: pcthumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: exhibition12,
    thumbnail: pcthumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: exhibition13,
    thumbnail: pcthumb13,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '14',
    source: exhibition14,
    thumbnail: pcthumb14,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '15',
    source: exhibition15,
    thumbnail: pcthumb15,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '16',
    source: exhibition16,
    thumbnail: pcthumb16,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '17',
    source: exhibition17,
    thumbnail: pcthumb17,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '18',
    source: exhibition18,
    thumbnail: pcthumb18,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '19',
    source: exhibition19,
    thumbnail: pcthumb19,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '20',
    source: exhibition20,
    thumbnail: pcthumb20,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '21',
    source: exhibition21,
    thumbnail: pcthumb21,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '22',
    source: exhibition22,
    thumbnail: pcthumb22,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '23',
    source: exhibition23,
    thumbnail: pcthumb23,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '24',
    source: exhibition24,
    thumbnail: pcthumb24,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '25',
    source: exhibition25,
    thumbnail: pcthumb25,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '26',
    source: exhibition26,
    thumbnail: pcthumb26,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '27',
    source: exhibition27,
    thumbnail: pcthumb27,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '28',
    source: exhibition28,
    thumbnail: pcthumb28,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '29',
    source: exhibition29,
    thumbnail: pcthumb29,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '30',
    source: exhibition30,
    thumbnail: pcthumb30,
    caption: 'Photo 16',
    description: '',
  },
]
const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: exhibition01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: exhibition02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: exhibition03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: exhibition04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: exhibition05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: exhibition06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: exhibition07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: exhibition08,
    thumbnail: thumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: exhibition09,
    thumbnail: thumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: exhibition10,
    thumbnail: thumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: exhibition11,
    thumbnail: thumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: exhibition12,
    thumbnail: thumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: exhibition13,
    thumbnail: thumb13,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '14',
    source: exhibition14,
    thumbnail: thumb14,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '15',
    source: exhibition15,
    thumbnail: thumb15,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '16',
    source: exhibition16,
    thumbnail: thumb16,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '17',
    source: exhibition17,
    thumbnail: thumb17,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '18',
    source: exhibition18,
    thumbnail: thumb18,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '19',
    source: exhibition19,
    thumbnail: thumb19,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '20',
    source: exhibition20,
    thumbnail: thumb20,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '21',
    source: exhibition21,
    thumbnail: thumb21,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '22',
    source: exhibition22,
    thumbnail: thumb22,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '23',
    source: exhibition23,
    thumbnail: thumb23,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '24',
    source: exhibition24,
    thumbnail: thumb24,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '25',
    source: exhibition25,
    thumbnail: thumb25,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '26',
    source: exhibition26,
    thumbnail: thumb26,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '27',
    source: exhibition27,
    thumbnail: thumb27,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '28',
    source: exhibition28,
    thumbnail: thumb28,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '29',
    source: exhibition29,
    thumbnail: thumb29,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '30',
    source: exhibition30,
    thumbnail: thumb30,
    caption: 'Photo 16',
    description: '',
  },
]

const siteTitle = 'Onodera Ryo | exhibition'

const Exhibition04 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
      <div className="portrait">
        <Exhibitionnav>
            "Nobody Knows" (2016)　Nov 11th - 16th　<br></br>Cast：Mai Kiryu / Mika Ushio / Jyuri Ihata / Yusaku Mori　At ROCKET OMOTESANDO
        </Exhibitionnav>
        <div class={`gall ${this.state.nonClass}`}>
        <MediaQuery query="(min-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES2.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        </div>
      </div>
      </div>
    </Layout>
  )
  }
}

export default Exhibition04